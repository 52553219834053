import React from 'react';
import './App.css';

const App = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container">
      <h1>VisionTweak</h1>
      <p>VisionTweak ontwikkeld diverse software pakketten en SaaS diensten. Binnenkort zult u hier meer informatie over vinden.</p>

      <p>Daarnaast doen wij voor diverse bedrijven het algehele systeembeheer en ontwikkelen wij software en marketing materiaal op maat.</p>

      <img src="/UnderConstruction.png" alt="Under Construction" className="under-construction-image" />
      <footer>
        © 2012 - {currentYear} <a href="https://www.visiontweak.com/" className="footer-link">VisionTweak</a>
      </footer>
    </div>
  );
}

export default App;